module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "ui-images": "static-2.747"
    },
    "depPathPrefixes": {
      "ui-images": "/ui-images/static-2.747"
    },
    "project": "ui-images",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};